import { useEffect, useState } from 'react';
import { FaBars, FaMinus, FaPlus } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import { useAppSelector } from 'src/redux/store';
import {
  DATALAYER_TYPE,
  sendDataLayerGlobal
} from 'src/services/member-auth/datalayer/send-datalayer';
import { useRouter } from 'next/router';
const NavMobile = () => {
  const [toggle, setToggle] = useState(false);
  const [expand, setExpand] = useState({ status: false, key: null });
  const navMobileData = useAppSelector((props) => props.navData.result);
  const router = useRouter();
  useEffect(() => {
    if (toggle) {
      document.getElementById('__next').classList.add('c-app');
    } else {
      document.getElementById('__next').classList.remove('c-app');
    }
  }, [toggle]);
  // console.log({ navMobileData })
  function handleToggle (_key, _index) {
    // console.log('handleToggle', _key, { toggle, expand });
    switch (_key) {
      case 'close':
        setToggle(false);
        setExpand({ key: null, status: false });
        break;
      case 'open':
        setToggle(!toggle);
        break;
      case 'menu':
        setToggle(true);
        setExpand({
          key: _index,
          status: expand?.key === _index ? !expand.status : true
        });
        break;
      default:
        setToggle(false);
        break;
    }
  }

  return (
    <>
      <div className='nav-mobile'>
        {toggle || expand.status
          ? (
          <CgClose onClick={() => handleToggle('close')} />
            )
          : (
          <FaBars
            onClick={() => {
              handleToggle('open');
            }}
          />
            )}

        <nav
          className={`nav-dropdown-mobile ${toggle ? 'visible' : 'invisible'}`}
        >
          <button onClick={() => handleToggle('close')} className='close-btn'>
            <CgClose />
          </button>
          {/* ---------------------------------- MENU ---------------------------------- */}
          <ul className={'nav-menu'}>
            {(toggle || expand.status) &&
              Array.isArray(navMobileData) &&
              navMobileData.length > 0 &&
              navMobileData.map((menu, index) => (
                <li key={index} className='active-menu'>
                  <a
                    href={menu?.sub.length > 0 ? '#' : `/${menu?.link}`}
                    title={menu?.nameTh}
                       //! DATALAYER
                       onClick={() =>
                         sendDataLayerGlobal({
                           type: DATALAYER_TYPE.TRACK_POSITION,
                           router: router?.pathname,
                           position: 'on:header',
                           section: 'header',
                           data: {
                             title: menu?.nameTh,
                             heading: `${menu?.nameTh}`
                           }
                         })
                      }
                  >
                    <div
                      className='list-menu'
                      onClick={() => handleToggle('menu', index)}
                    >
                      <div className='item-menu'>
                        <span className='menu'>{menu?.nameTh}</span>

                        {/* ------------------------------- Icon Expand ------------------------------ */}
                        {Array.isArray(menu?.sub) &&
                          menu?.sub.length > 0 &&
                          (expand?.key === index && expand?.status
                            ? (
                            <FaMinus />
                              )
                            : (
                            <FaPlus />
                              ))}
                      </div>

                      {/* --------------------------------- SUBMENU -------------------------------- */}
                      {expand?.key === index &&
                        expand?.status &&
                        Array.isArray(menu.sub) && (
                          <ul className='nav-sub-menu'>
                            {menu.sub.map((subMenu, index) => (
                              <li key={index} className='active-sub-menu'>
                                <a
                                  href={subMenu?.link}
                                  title={subMenu?.nameTh}
                                   //! DATALAYER
                                   onClick={() =>
                                     sendDataLayerGlobal({
                                       type: DATALAYER_TYPE.TRACK_POSITION,
                                       router: router?.pathname,
                                       position: 'on:header',
                                       section: 'header',
                                       data: {
                                         title: subMenu?.nameTh,
                                         heading: `${menu?.nameTh}:${subMenu?.nameTh}`
                                       }
                                     })
                                  }
                                >
                                  <div className='list-sub-menu'>
                                    <span className='sub-menu'>
                                      {' '}
                                      {subMenu?.nameTh}{' '}
                                    </span>
                                  </div>
                                </a>
                              </li>
                            ))}
                          </ul>
                      )}
                    </div>
                  </a>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default NavMobile;
